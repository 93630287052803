<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <b-link class="brand-logo">
        <img :src="logo" alt="">
        <h2 class="brand-text text-primary ml-1">HBase</h2>
      </b-link>

      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img fluid :src="imgUrl" alt="Login V2" />
        </div>
      </b-col>

      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <Alert :error="errors" :success="success" :successMsg="successMsg" />

          <span v-if="isPincodeSent">
            <validation-observer ref="loginForm">
              <b-form class="auth-login-form mt-2" @submit.prevent="login">
                <b-form-group label="" label-for="login-pincode">
                  <div class="d-flex justify-content-between">
                    <label for="login-pincode">Verify Pincode</label>
                    <b-link @click="resendPin">
                      <small>Resend email</small>
                    </b-link>
                  </div>
                  <b-form-input
                    id="login-pincode"
                    v-model="pinCode"
                    name="login-pincode"
                  />
                </b-form-group>

                <b-button type="submit" variant="success" block>
                  Verify
                </b-button>

                <b-link @click="backToLogin()">
                  <small>Back to login</small>
                </b-link>
              </b-form>
            </validation-observer>
          </span>

          <span v-else>
            <validation-observer ref="loginForm" #default="{ invalid }">
              <b-form class="auth-login-form mt-2" @submit.prevent="login">
                <b-form-group label="Username" label-for="login-email">
                  <validation-provider
                    #default="{ errors }"
                    name="Username"
                    vid="email"
                    rules="required"
                  >
                    <b-form-input
                      id="login-email"
                      v-model="userEmail"
                      :state="errors.length > 0 ? false : null"
                      name="login-email"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group>
                  <div class="d-flex justify-content-between">
                    <label for="login-password">Password</label>
                    <b-link :to="{ name: 'auth-forgot-password' }">
                      <small>Forgot Password?</small>
                    </b-link>
                  </div>
                  <validation-provider
                    #default="{ errors }"
                    name="Password"
                    vid="password"
                    rules="required"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid' : null"
                    >
                      <b-form-input
                        id="login-password"
                        v-model="password"
                        :state="errors.length > 0 ? false : null"
                        class="form-control-merge"
                        :type="passwordFieldType"
                        name="login-password"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="passwordToggleIcon"
                          @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group>
                  <b-form-checkbox
                    id="remember-me"
                    v-model="status"
                    name="checkbox-1"
                  >
                    Remember Me
                  </b-form-checkbox>
                </b-form-group>

                <b-button
                  type="submit"
                  variant="primary"
                  block
                  :disabled="invalid"
                >
                  Sign in
                </b-button>
              </b-form>
            </validation-observer>

            <!-- <b-card-text class="text-center mt-2">
              <span>New on our platform? </span>
              <b-link :to="{ name: 'auth-register' }">
                <span>&nbsp;Create an account</span>
              </b-link>
            </b-card-text> -->
          </span>
        </b-col>
      </b-col>
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { required, email } from '@validations';
import { togglePasswordVisibility } from '@core/mixins/ui/forms';
import store from '@/store/index';
import { getHomeRouteForLoggedInUser } from '@/auth/utils';

import md5 from 'md5';
import authApi from '@api/auth';

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import Alert from '@/views/components/general/Alert.vue';

export default {
  components: {
    Alert,
  },
  mixins: [togglePasswordVisibility],
  data: () => ({
    errors: {},
    success: false,
    successMsg: '',
    isPincodeSent: false,

    status: '',
    password: '',
    userEmail: '',
    pinCode: '',
    sideImg: require('@/assets/images/pages/login-v2.svg'),
    logo: require('@/assets/images/logo/qcs_logo.png'),

    // validation rules
    required,
    email,
  }),
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon';
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg');
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  methods: {
    backToLogin() {
      this.isPincodeSent = false;
      this.success = false;
      this.errors = {};

      this.status = '';
      this.password = '';
      this.userEmail = '';
      this.pinCode = '';
    },
    resendPin() {
      this.pinCode = null;

      this.login();

      const data = {
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: `Pincode resent`,
          icon: 'MailIcon',
          variant: 'success',
          text: `Pincode was sent to your email.`,
        },
      };

      this.$toast(data);
    },
    login() {
      this.$refs.loginForm.validate().then((success) => {
        if (success) {
          const formData = {
            username: this.userEmail,
            password: md5(this.password),
            code: this.pinCode,
          };

          authApi
            .login(formData)
            .then(({ data }) => {
              if (data.type == '') {
                if (data.ispincodesent) {
                  this.isPincodeSent = true;
                  this.errors = {};
                  this.success = true;
                  this.successMsg = data.message;

                  return;
                }

                if (!data.ispincodesent) {
                  this.success = false;
                  this.errors = {
                    response: {
                      data: {
                        errors: [{ title: data.message }],
                      },
                    },
                  };
                  return;
                }

                return;
              }

              this.errors = {};
              this.success = true;
              data.ability = [
                {
                  action: 'manage',
                  subject: 'all',
                },
              ];
              data.role = data.user.data.roles[0];

              this.$store.dispatch('hbaseAuth/authenticate', data);

              this.$ability.update(data.ability);
              this.redirectToPage(data);
            })
            .catch((err) => {
              this.success = false;
              this.errors = err;
            })
            .finally(() => {
              //
            });
        }
      });
    },
    redirectToPage(userData) {
      this.$router
        .replace(getHomeRouteForLoggedInUser(userData.role))
        .then(() => {
          this.toastAlert(userData);
        });
    },
    toastAlert(user) {
      const u = user?.user?.data;
      let fullName = u.first_name;
      fullName += u.last_name != '' ? ` ${u.last_name}` : '';

      const data = {
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: `Welcome ${fullName}`,
          icon: 'CoffeeIcon',
          variant: 'success',
          text: `You have successfully logged in. Now you can start to explore!`,
        },
      };

      this.$toast(data);
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
